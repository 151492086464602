<template>
  <picture>
    <source srcset="@/assets/bg-lg.webp" type="image/webp">
    <source srcset="@/assets/bg-lg.jpg" type="image/jpeg">
    <img src="@/assets/bg-lg.jpg" alt="" role="presentation">
  </picture>
</template>

<script>
export default {
  name: 'BackgroundImage',
};
</script>

<style scoped>
picture {
  width: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -1;
}

img {
  width: 100%;
  height: 370px;
  object-position: 50% 50%;
  object-fit: cover;
}
</style>

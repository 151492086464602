/* eslint-disable global-require, no-unused-vars */

const boardStack = {
  // https://www.istockphoto.com/photo/stacked-lumber-at-a-building-site-gm104295010-12493044
  webp: require('@/assets/products/stock/stack-boards.jpg'),
  jpg: require('@/assets/products/stock/stack-boards.jpg'),
  alt: 'Stack of Pallet decking ready to be applied to a new recycled pallet.',
};

const twoByFourStack = {
  // https://www.istockphoto.com/photo/wood-cedar-board-isolated-on-white-background-gm876143292-244560754
  webp: require('@/assets/products/stock/2x4-bundle.jpg'),
  jpg: require('@/assets/products/stock/2x4-bundle.jpg'),
  alt: 'Stacks of 2 by 4 boards which can be used as straight pallet stringers.',
};

const blockPalletStack = {
  // https://www.istockphoto.com/photo/stack-of-wooden-pallets-gm512602011-46961844
  webp: require('@/assets/products/stock/block-pallet-stack.jpg'),
  jpg: require('@/assets/products/stock/block-pallet-stack.jpg'),
  alt: 'Three wooden block pallets on a stack with good condition pallet decking.',
};

const blockPalletStackWarehouse = {
  // https://www.istockphoto.com/photo/stack-of-wooden-pallets-at-warehouse-gm1194596989-340215951
  webp: require('@/assets/products/stock/block-pallet-stack-warehouse.jpg'),
  jpg: require('@/assets/products/stock/block-pallet-stack-warehouse.jpg'),
  alt: 'Stacks of block pallets in a warehouse. These are strong and can support heavy products.',
};

const plasticPalletStack = {
  // https://www.istockphoto.com/photo/plastic-shipping-pallet-for-sale-and-for-rent-business-industry-plastic-pallet-gm1161324719-318178254
  webp: require('@/assets/products/stock/plastic-pallet-stack.jpg'),
  jpg: require('@/assets/products/stock/plastic-pallet-stack.jpg'),
  alt: 'Plastic shipping pallet for sale and for rent business. Industry plastic pallet stacked at factory warehouse. Cargo and shipping business concept. Pile of plastic pallet for distribution and delivery.',
};

const stringPalletStack = {
  // https://www.istockphoto.com/photo/pile-of-shipping-pallettes-gm173935243-9624477
  webp: require('@/assets/products/stock/stringer-pallet-stack.jpg'),
  jpg: require('@/assets/products/stock/stringer-pallet-stack.jpg'),
  alt: 'Stacks of 4-way stringer pallets. The long-way stringers along the center of the pallet have notches to allow a forklift to pick up the pallet from any side.',
};

const straightStringPalletStack = {
  // https://www.istockphoto.com/photo/wooden-pallets-gm184834520-18100731
  webp: require('@/assets/products/stock/straight-stringer-pallet-stack.jpg'),
  jpg: require('@/assets/products/stock/straight-stringer-pallet-stack.jpg'),
  alt: 'Stacks of straight-stringer pallets. Instead of having stringer notches for a 4-way pallet, this is a 2-way pallet.',
};

const fortyEightByFortyPalletStack = {
  // https://www.istockphoto.com/photo/single-wooden-pallet-gm537594563-57918834
  webp: require('@/assets/products/stock/48x40-grade-a.jpg'),
  jpg: require('@/assets/products/stock/48x40-grade-a.jpg'),
  alt: 'High quality recycled grade A 48 x 40 pallet.',
};

const fortyEightByFortyPalletDimensions = {
  // https://www.shutterstock.com/image-vector/american-wooden-pallet-perspective-front-side-272294222
  webp: require('@/assets/products/stock/48x40-dimensions.jpg'),
  jpg: require('@/assets/products/stock/48x40-dimensions.jpg'),
  alt: 'Pallet dimensions in a PDS (Pallet Design System) for standard 48 by 40 inch pallet in metric.',
};

const squarePallet = {
  // https://www.istockphoto.com/photo/pallet-wood-isolated-on-white-background-gm510858524-86425449
  webp: require('@/assets/products/stock/square-pallet.jpg'),
  jpg: require('@/assets/products/stock/square-pallet.jpg'),
  alt: 'Square pallet stock photo.',
};

const euroPalletStack = {
  // https://www.istockphoto.com/photo/wooden-euro-palette-gm1147074033-309291574
  webp: require('@/assets/products/stock/eur-pallet-stock.jpg'),
  jpg: require('@/assets/products/stock/eur-pallet-stock.jpg'),
  alt: 'European EUR stamped wooden pallets for transportation in the EU.',
};

const euroAltPalletStack = {
  // https://www.istockphoto.com/photo/pallet-gm186819772-19373181
  webp: require('@/assets/products/stock/eur-alt-pallet.jpg'),
  jpg: require('@/assets/products/stock/eur-alt-pallet.jpg'),
  alt: 'Two European EUR stamped wooden pallets approved for the EU.',
};

const euroEpalStack = {
  webp: require('@/assets/products/stock/euro-epal-stack.jpg'),
  jpg: require('@/assets/products/stock/euro-epal-stack.jpg'),
  alt: 'Stack of European EPAL pallets stamped EUR and EPAL.',
};

const twentyByFortyEight = {
  webp: require('@/assets/products/stock/20x48-pallet.jpg'),
  jpg: require('@/assets/products/stock/20x48-pallet.jpg'),
  alt: 'Young man with hand pallet truck or pallet jack and stack of cardboard boxes on pallet in distribution warehouse.',
};

const fortyEightByForty = {
  webp: require('@/assets/products/48x40-pallets.jpg'),
  jpg: require('@/assets/products/48x40-pallets.jpg'),
  alt: 'High quality recycled grade A 48 x 40 pallet.',
};

const canPallet = {
  webp: require('@/assets/products/48x40-pallets.jpg'),
  jpg: require('@/assets/products/48x40-pallets.jpg'),
  alt: 'A single wooden block can pallet with pallet decking boards.',
};

const canPalletsGlassPallets = {
  webp: require('@/assets/products/can-pallets-glass-pallets.jpg'),
  jpg: require('@/assets/products/can-pallets-glass-pallets.jpg'),
  alt: 'A stack of can pallets and glass pallets mixed together',
};

const cardboardBale = {
  webp: require('@/assets/products/cardboard-bale.jpg'),
  jpg: require('@/assets/products/cardboard-bale.jpg'),
  alt: 'Two cardboard bales stacked and bound on a wooden pallet.',
};

const cardboard = {
  webp: require('@/assets/products/cardboard.jpg'),
  jpg: require('@/assets/products/cardboard.jpg'),
  alt: 'Many cardboard bales on pallets in a warehouse.',
};

const coresDropTrailerProgram = {
  webp: require('@/assets/products/cores-drop-trailer-program.jpg'),
  jpg: require('@/assets/products/cores-drop-trailer-program.jpg'),
  alt: 'A trailer full of damaged pallets to be returned for remanufacturing.',
};

const cp1 = {
  webp: require('@/assets/products/CP1.jpg'),
  jpg: require('@/assets/products/CP1.jpg'),
  alt: 'A stack of CP1 stamped wood pallets.',
};

const cp3 = {
  webp: require('@/assets/products/CP3.jpg'),
  jpg: require('@/assets/products/CP3.jpg'),
  alt: 'A stack of CP3 stamped wood pallets.',
};

const deckingStacked = {
  webp: require('@/assets/products/decking-stacked.jpg'),
  jpg: require('@/assets/products/decking-stacked.jpg'),
  alt: 'Pallet decking neatly stacked in a container.',
};

const decking = {
  webp: require('@/assets/products/decking.jpg'),
  jpg: require('@/assets/products/decking.jpg'),
  alt: 'Pallet decking loosely piled in a container.',
};

const euroPallet = {
  webp: require('@/assets/products/euro-pallet.jpg'),
  jpg: require('@/assets/products/euro-pallet.jpg'),
  alt: 'A stack of stamped EPAL pallets.',
};

const euroCollar = {
  webp: require('@/assets/products/euro-collar.jpg'),
  jpg: require('@/assets/products/euro-collar.jpg'),
  alt: 'A wooden collar with metal corners for EPAL pallets.',
};

const lumber = {
  webp: require('@/assets/products/lumber.jpg'),
  jpg: require('@/assets/products/lumber.jpg'),
  alt: 'Four pallets of lumber boards stacked two high.',
};

const newLumberCutStock = {
  webp: require('@/assets/products/new-lumber-cut-stock.jpg'),
  jpg: require('@/assets/products/new-lumber-cut-stock.jpg'),
  alt: 'Freshly cut new lumber for pallet manufacturing.',
};

const plasticCanPallets = {
  webp: require('@/assets/products/plastic-can-pallets.jpg'),
  jpg: require('@/assets/products/plastic-can-pallets.jpg'),
  alt: 'Plastic can pallets stacked up ready for use.',
};

const recycledPaper = {
  webp: require('@/assets/products/recycled-paper.jpg'),
  jpg: require('@/assets/products/recycled-paper.jpg'),
  alt: 'Bales of paper to be recycled.',
};

const smallDecking = {
  webp: require('@/assets/products/small-decking.jpg'),
  jpg: require('@/assets/products/small-decking.jpg'),
  alt: 'Pallets of individual pallet decking boards stacked high.',
};

const stringers = {
  webp: require('@/assets/products/stringers.jpg'),
  jpg: require('@/assets/products/stringers.jpg'),
  alt: 'A pallet of thick pallet stringer boards.',
};

export default {
  products: [
    {
      slug: 'pallet-decking',
      name: 'Pallet Decking',
      category: 'Materials',
      alternatives: [
        '26” Pallet Decking',
        '30” Pallet Decking',
        '32” Pallet Decking',
        '36” Pallet Decking',
        '40” Pallet Decking',
        '42” Pallet Decking',
        '45” Pallet Decking',
        '48” Pallet Decking',
      ],
      description: 'Pallet deck boards for repairing/replacing top boards for pallets.',
      longDescription: 'Pallet deck boards sit on top of stringers or blocks and support product that sits on top of the pallet. These are available at different sizes to support a variety of pallet dimensions.',
      related: ['stringers', '48x40-cores', '48x40-pallets', 'specialty-pallets'],
      images: [
        decking,
        smallDecking,
        deckingStacked,
      ],
      ogImage: require('@/assets/products/decking.jpg'),
    },
    {
      slug: 'stringers',
      name: 'Stringers',
      category: 'Materials',
      alternatives: [
        '20” Stringers',
        '26” Stringers',
        '36” Stringers',
        '40” Stringers',
        '42” Stringers',
        '45” Stringers',
        '46” Stringers',
        '48” Stringers Large Stringers',
      ],
      description: 'Notched side and center pallet boards that allow the pallet to be lifted.',
      longDescription: 'We offer pallet stringers in 2 way or 4 way, which is the number of sides a pallet can be picked up by. 2 way stringers are simply straight boards that run the length of the pallet, where 4 way stringers are notched.',
      related: ['pallet-decking', '48x40-cores', '48x40-pallets', 'specialty-pallets'],
      images: [
        stringers,
      ],
      ogImage: require('@/assets/products/stringers.jpg'),
    },
    {
      slug: '48x40-pallets',
      name: '48" x 40" Pallets',
      category: 'Pallets',
      alternatives: [
        '48" x 40" Grade A Pallets',
        '48" x 40" Grade B Pallets',
      ],
      description: 'economical industry standard workhorse.',
      longDescription: 'Hardwood pallets are an economical alternative to plastic pallets. Available new or recycled. The standard in storage and shipping, these pallets are stackable to save space and reusable for new loads.',
      related: ['48x48-pallets', '48x40-cores', '42x42-pallets', 'specialty-pallets'],
      images: [
        fortyEightByForty,
      ],
      ogImage: require('@/assets/products/48x40-pallets.jpg'),
    },
    {
      slug: 'euro-pallets',
      name: 'Euro pallets',
      category: 'Pallets',
      alternatives: [],
      description: 'EPAL and EUR stamped pallets.',
      longDescription: `These pallets must be stamped EPAL or EUR - they are standardized by
      <a href="https://www.epal-pallets.org/eu-en/">The European Pallet Association</a>
      Popular in international shipping applications, we offer heat treating for these along
      with the rest of our wooden pallets. Even if you aren't shipping over seas, this could
      still be the perfect product for you.`,
      related: ['can-pallets', 'cp-pallets', 'glass-pallets', '48x48-pallets'],
      images: [
        euroPallet,
      ],
      ogImage: require('@/assets/products/euro-pallet.jpg'),
    },
    {
      slug: 'can-pallets',
      name: 'Can Pallets',
      category: 'Pallets',
      alternatives: [
        'Plastic Can Pallets',
        'Wooden Can Pallets',
      ],
      description: 'Block pallets for canned goods and heavy products.',
      longDescription: 'Popular in the food industry because of their consistency and strength. These 44" x 56" wood and plastic pallets are ready to use for canned foods, beverages, or other heavy products. These pallets are typically constructed with blocks rather than stringers for the increased strength.',
      related: ['euro-pallets', 'cp-pallets', 'glass-pallets', '48x48-pallets'],
      images: [
        plasticCanPallets,
        canPalletsGlassPallets,
        canPallet,
      ],
      ogImage: require('@/assets/products/plastic-can-pallets.jpg'),
    },
    {
      slug: 'glass-pallets',
      name: 'Glass Pallets',
      category: 'Pallets',
      alternatives: [
        'Plastic Glass Pallets',
        'Wooden Glass Pallets',
        'Plywood Top Glass Pallets',
      ],
      description: 'Flat topped block pallets in plastic, wood, and plywood.',
      longDescription: `These 54" x 44" wood and plastic pallets are ready to use for
      beverages, jarred goods, or other heavy products. These pallets are typically
      constructed with blocks rather than stringers for the increased strength.
      These can be made with slatted tops or with solid tops made from plywood.`,
      related: ['can-pallets', 'cp-pallets', 'euro-pallets', '48x48-pallets'],
      images: [
        canPalletsGlassPallets,
      ],
      ogImage: require('@/assets/products/can-pallets-glass-pallets.jpg'),
    },
    {
      slug: 'cp-pallets',
      name: 'CP Pallets (All Sizes & Styles)',
      category: 'Pallets',
      alternatives: [
        'CP 1',
        'CP 2',
        'CP 3',
        'CP 4',
        'CP 5',
        'CP 6',
        'CP 7',
        'CP 8',
        'CP 9',
      ],
      description: 'Stamped Chemical Industry Pallets.',
      longDescription: `CP pallets are a standardized design of the chemical industry designed to meet
      9 different specifications. You can identify these pallets by the CP1 through CP9 stamp on the pallet.
      These are block pallets with high clearance which work nicely with both forklifts and pallet jacks.`,
      related: ['euro-pallets', 'can-pallets', 'glass-pallets', '48x48-pallets'],
      images: [
        cp1,
        cp3,
      ],
      ogImage: require('@/assets/products/CP1.jpg'),
    },
    {
      slug: 'specialty-pallets',
      name: 'Specialty Pallets (New & Used)',
      category: 'Pallets',
      alternatives: [],
      description: 'The perfect pallet to meet your exact needs.',
      longDescription: 'Looking for a custom pallet that\'s a perfect fit for your product? When you work with us, we can find exactly what you need. \n\n When you\'re looking for a custom pallet, it\'s possible to find and quote you for a recycled pallet but often to get something unique it will be custom built to specification. \n\n We work with pallet manufacturers and distributors so you don\'t have to. Your specialty pallets are built to Pallet Design System (PDS) specification with the exact size and weight requirements. We\'re your one-stop-shop for all your pallet needs.',
      related: ['48x48-pallets', '48x40-pallets', 'cp-pallets', 'glass-pallets'],
      images: [
        fortyEightByFortyPalletDimensions,
      ],
      ogImage: require('@/assets/products/stock/48x40-dimensions.jpg'),
    },
    {
      slug: '42x42-pallets',
      name: '42" x 42" Pallets',
      category: 'Pallets',
      alternatives: [],
      description: 'Square pallets 3 and a half feet wide.',
      longDescription: 'Hardwood pallets are an economical alternative to plastic pallets. Available new or recycled. The standard in storage and shipping, these pallets are stackable to save space and reusable for new loads.',
      related: ['48x48-pallets', '48x40-pallets', '20x48-pallets', 'specialty-pallets'],
      images: [
        squarePallet,
      ],
      ogImage: require('@/assets/products/stock/square-pallet.jpg'),
    },
    {
      slug: '48x48-pallets',
      name: '48" x 48" Pallets',
      category: 'Pallets',
      alternatives: [],
      description: 'Square pallets that are 4 feet wide.',
      longDescription: 'Hardwood pallets are an economical alternative to plastic pallets. Available new or recycled. The standard in storage and shipping, these pallets are stackable to save space and reusable for new loads.',
      related: ['48x40-pallets', '48x40-cores', '42x42-pallets', 'specialty-pallets'],
      images: [
        squarePallet,
      ],
      ogImage: require('@/assets/products/stock/square-pallet.jpg'),
    },
    {
      slug: '20x48-pallets',
      name: '20" x 48" Pallets',
      category: 'Pallets',
      alternatives: [],
      description: 'Narrow pallets for smaller loads or product displays.',
      longDescription: 'Hardwood pallets are an economical alternative to plastic pallets. Available new or recycled. The standard in storage and shipping, these pallets are stackable to save space and reusable for new loads.',
      related: ['48x48-pallets', '48x40-pallets', '42x42-pallets', 'specialty-pallets'],
      images: [
        twentyByFortyEight,
      ],
      ogImage: require('@/assets/products/stock/20x48-pallet.jpg'),
    },
    {
      slug: '48x40-cores',
      name: '48" x 40" Cores',
      category: 'Materials',
      alternatives: [],
      description: 'Broken or damaged pallet cores for recycling.',
      longDescription: 'Have broken pallets sitting around? They may be worth something! We buy and sell 48" x 40" pallet cores - these can be repaired and made like new to be reused for years to come.',
      related: ['stringers', 'pallet-decking', '48x40-pallets', '48x48-pallets'],
      images: [
        coresDropTrailerProgram,
      ],
      ogImage: require('@/assets/products/cores-drop-trailer-program.jpg'),
    },
  ],
};

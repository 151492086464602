<template>
  <nav id="nav">
    <div class="menu-button-container">
      <label>Menu</label>
      <button
        class="hamburger hamburger--spin"
        :class="{ 'is-active': isOpen }"
        type="button"
        aria-label="Menu"
        aria-controls="navigation"
        @click="isOpen = !isOpen"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
    <ul :class="{ show: isOpen }" class="nav-list">
      <li>
        <router-link to="/">Home</router-link>
      </li>
      <li>
        <router-link to="/about">About</router-link>
      </li>
      <li>
        <router-link to="/services">Services</router-link>
      </li>
      <li>
        <router-link to="/products">Products</router-link>
      </li>
      <li>
        <router-link to="/contact">Contact</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Nav',
  data: () => ({
    isOpen: false,
  }),
};
</script>

<style lang="scss" scoped>
@import "~include-media/dist/_include-media.scss";
@import "~hamburgers/_sass/hamburgers/hamburgers.scss";

.menu-button-container {
  background-color: white;
  text-align: right;

  @include media(">=phone") {
    display: none;
  }

  label {
    font-size: 32px;
    text-transform: uppercase;
    padding: 15px 0;
  }
}

.show {
  max-height: 400px;
}

ul {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  overflow: hidden;
  padding: 0;
  margin: 0;
  max-height: 400px;
  transition: max-height 0.5s ease-in-out;

  @include media("<=phone") {
    flex-wrap: wrap;
    max-height: 0;
  }

  li {
    flex: 1 1 100%;
    background-color: black;
    color: white;
    height: 3rem;

    display: flex;
    flex-direction: row;
    align-items: stretch;
    text-transform: uppercase;

    cursor: pointer;

    :hover,
    :active,
    .router-link-active {
      background-color: var(--accent-color);
    }

    a {
      flex: 1;
      color: white;
      text-decoration: none;
      text-align: center;
      line-height: 50px;
      transition: background-color 0.2s ease-in-out;
    }
  }
}
</style>

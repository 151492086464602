<template>
  <div class="main">
    <background-image />
    <header>
    </header>
    <div :class="{ 'drawer-panel': true, 'drawer-out': show }">
      <div class="form-container">
        <button class="drawer-button" @click="show = !show">Contact</button>
          <contact-form />
      </div>
    </div>
    <main>
      <title-box />
      <div class="content">
        <Nav/>
        <router-view v-slot="{ Component }">
          <transition
            v-if="transition"
            :name="transitionName"
            mode="out-in"
          >
            <component class="main-route" :is="Component" />
          </transition>
          <component
            v-else
            class="main-route"
            :is="Component"
          />
        </router-view>
        <p>Copyright &copy; Narrow Gate. All Rights Reserved</p>
      </div>
    </main>
    <aside class="left" />
    <aside class="right" />
    <footer>
    </footer>
  </div>
</template>

<script>
import BackgroundImage from '@/components/BackgroundImage';
import Nav from '@/components/Nav';
import TitleBox from '@/components/TitleBox';
import ContactForm from './components/ContactForm';

export default {
  name: 'App',
  components: {
    BackgroundImage,
    Nav,
    TitleBox,
    ContactForm,
  },
  data: () => ({
    show: false,
    transition: true,
  }),
  watch: {
    $route(to, from) {
      // For some reason this transition is bugged when going to a product page
      if (Object.keys(to.params).length !== 0) {
        this.transition = false;
      } else {
        this.transition = true;
      }

      this.transitionName = from.meta.order > to.meta.order ? 'slide-left' : 'slide-right';
    },
  },
};
</script>

<style lang="scss">
@import "~@/styles/base.scss";
@import "~@/styles/classes.scss";

header {
  padding-top: 200px;
  @include media("<=phone") {
    padding-top: 100px;
  }
}

.drawer-panel {
  @include media("<=desktop") {
    display: none;
  }

  position: absolute;
  left: 0;
  height: 100%;
  z-index: 1;
  border-radius: 5px;
  transform: translateX(-100%);
  transition: all 0.3s linear;

  .form-container {
    position: sticky;
    top: 150px;
    padding: 25px;
    background: white;
    border: 1px solid black;
    border-radius: 5px;

    .drawer-button {
      position: absolute;
      top: 49%;
      right: -110px;
      padding: 16px;
      background-color: var(--accent-color);
      font-size: 24px;
      color: white;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 3px;
      border: none;
      cursor: pointer;
      transform: rotate(-90deg);
    }
  }
}
.drawer-out {
  transform: translateX(0%);
}

.slide-left-leave-active {
  transition: all 0.3s linear;
}
// note - no need to change where we are leaving from
// .slide-left-leave-from
.slide-left-leave-to {
  transform: translate(500px);
  opacity: 0;
}

.slide-left-enter-active {
  transition: all 0.3s linear;
}
.slide-left-enter-from {
  opacity: 0;
  transform: translateX(-500px);
}
.slide-left-enter-to {
  transform: translateX(0px);
  opacity: 1;
}

.slide-right-leave-active {
  transition: all 0.3s linear;
}
// note - no need to change where we are leaving from
// .slide-right-leave-from
.slide-right-leave-to {
  transform: translate(-500px);
  opacity: 0;
}

.slide-right-enter-active {
  transition: all 0.3s linear;
}
.slide-right-enter-from {
  opacity: 0;
  transform: translateX(500px);
}
.slide-right-enter-to {
  transform: translateX(0px);
  opacity: 1;
}
</style>

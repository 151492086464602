<template>
  <div>
    <form
      id="contact-form"
      action="https://formspree.io/f/mldrbwne"
      method="post"
    >
      <h3>Have Questions or Need a Quote?</h3>
      <h4>Connect with us now</h4>
      <div class="input-field" :class="{ active: !!name, focused: focus.name }">
        <label class="form-label" for="name">Name</label>
        <input
          id="name"
          v-model="name"
          type="text"
          name="name"
          placeholder="Name *"
          @focus="focus.name = true"
          @blur="focus.name = false"
        >
      </div>
      <div class="input-field" :class="{ active: !!email, focused: focus.email }">
        <label class="form-label" for="email">Email</label>
        <input
          id="email"
          v-model="email"
          type="email"
          name="email"
          placeholder="Email *"
          @focus="focus.email = true"
          @blur="focus.email = false"
        >
      </div>
      <div class="input-field" :class="{ active: !!phone, focused: focus.phone }">
        <label class="form-label" for="phone">Phone</label>
        <input
          id="phone"
          v-model="phone"
          type="tel"
          name="phone"
          placeholder="Phone"
          @focus="focus.phone = true"
          @blur="focus.phone = false"
        />
      </div>
      <div class="input-field" :class="{ active: !!subject, focused: focus.subject }">
        <label class="form-label" for="subject">Subject</label>
        <input
          id="subject"
          v-model="subject"
          type="text"
          name="subject"
          placeholder="Subject"
          @focus="focus.subject = true"
          @blur="focus.subject = false"
        >
      </div>
      <div class="input-field" :class="{ active: !!message, focused: focus.message }">
        <label class="form-label" for="message">Message</label>
        <textarea
          id="message"
          v-model="message"
          name="message"
          placeholder="Message *"
          @focus="focus.message = true"
          @blur="focus.message = false"
        ></textarea>
      </div>
      <div class="input-field input-field-submit">
        <input type="submit" value="Submit">
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ContactForm',
  data: () => ({
    focus: {
      name: false,
      email: false,
      phone: false,
      subject: false,
      message: false,
    },
  }),
};
</script>

<style lang="scss" scoped>
h3 {
  text-align: center;
  padding: 0;
  font-size: 24px;
  color: #1a1a1a;
}

h4 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  padding: 20px;
  padding-bottom: 0;
  font-size: 28px;
  color: var(--accent-color);
}
</style>

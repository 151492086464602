module.exports = [
  {
    path: '/',
    meta: {
      order: 0,
      title: 'We Buy & Sell Pallets Nationally • Narrow Gate',
      metaTags: [
        {
          name: 'description',
          content: 'We are dedicated to finding you the best price for the quality products you need. You can call on us for all of your pallet, lumber, cardboard, & plastic needs. If you need help selling your products, we\'ve got the connections for that as well.',
        },
      ],
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/about',
    meta: {
      order: 1,
      title: 'About Our Company • Narrow Gate',
      metaTags: [
        {
          name: 'description',
          content: 'Narrow Gate was founded by John Amoruso who grew up around manufacturing and is now leveraging his experience in product manufacturing and transportation to deliver the best pallet prices.',
        },
      ],
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/services',
    meta: {
      order: 2,
      title: 'Industrial Recycling & Material Transportation Services • Narrow Gate',
      metaTags: [
        {
          name: 'description',
          content: 'We buy and sell new and used pallets nationally. We provide pallets of all sizes, pallet material and lumber, heat treating services, trailer drop services, and more.',
        },
      ],
    },
    component: () => import(/* webpackChunkName: "services" */ '../views/Services.vue'),
  },
  {
    path: '/products',
    meta: {
      order: 3,
      title: 'Our Pallets & Boards • Narrow Gate',
      metaTags: [
        {
          name: 'description',
          content: 'Get the pallets you need at Narrow Gate. Our friendly experts will find the perfect pallets for your needs at the right price - delivered straight to you.',
        },
      ],
    },
    component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue'),
  },
  {
    // unique slug-based routing is best for SEO and analytics
    path: '/product/:slug',
    meta: {
      order: 4,
      title: 'Pallets',
      metaTags: [
        {
          name: 'description',
          content: 'New and recycled pallets and lumber material.',
        },
      ],
      sitemap: {
        // this is unfortunately hard-coded. Sitemap plugin has little access.
        // What is allowed without the full access to node is confusing
        slugs: [
          'pallet-decking',
          'stringers',
          '48x40-pallets',
          'euro-pallets',
          'can-pallets',
          'glass-pallets',
          'cp-pallets',
          'specialty-pallets',
          '42x42-pallets',
          '48x48-pallets',
          '20x48-pallets',
          '48x40-cores',
        ],
      },
    },
    component: () => import(/* webpackChunkName: "product" */ '../views/Product.vue'),
  },
  {
    path: '/contact',
    meta: {
      order: 5,
      title: 'Contact Us • Narrow Gate',
      metaTags: [
        {
          name: 'description',
          content: 'Connect with Narrow Gate - our professionals will find the material you need and bring it to you anywhere in the country. Looking to get rid of pallets, lumber, plastic, paper, or cardboard? We\'ll set up the transportation and take it away. Reach out to our team today!',
        },
      ],
    },
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    meta: {
      title: 'Page Not Found • Narrow Gate',
      metaTags: [
        {
          name: 'description',
          content: 'This page could not be found.',
        },
      ],
    },
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),
  },
];

import { createRouter, createWebHistory } from 'vue-router';
import productData from '@/data/Products';
import { updateMeta } from '@/lib/RouteFunctions';
import routes from './routes';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// This callback runs before every route change, including on page load.
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  const { products } = productData;
  const updatedTo = to;

  if (to.path.startsWith('/product/')) {
    // get current product from slug - use [array].find bc it returns first
    const currProduct = products.find((product) => product.slug === to.params.slug);

    updatedTo.matched[0].meta.title = `${currProduct.name} • Narrow Gate`;
    updatedTo.matched[0].meta.metaTags = [
      { name: 'description', content: currProduct.description },
      { name: 'og:type', content: 'og:product' },
      { name: 'og:title', content: currProduct.name },
      {
        name: 'og:description',
        content: currProduct.description,
      },
      {
        name: 'og:url',
        content: `${window.location.origin}${to.fullPath}`,
      },
      {
        name: 'og:image',
        content: `${window.location.origin}${currProduct.ogImage}`,
      },
    ];
  }

  updateMeta(updatedTo, from, next);
  next();
});

export default router;

<template>
  <div class="title-box">
    <a href="/"><h1>Narrow Gate</h1></a>
    <div class="passage">
      <span class="verse">
        Enter by the narrow gate; for wide is the gate and broad is the way that
        leads to destruction, and there are many who go in by it. Because narrow
        is the gate and difficult is the way which leads to life, and there are
        few who find it.
      </span>
      <span class="reference">- Matthew 7:13-14</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~include-media/dist/_include-media.scss";

.title-box {
  background-color: rgb(100, 100, 100);
  color: white;
  padding: 8px;

  h1 {
    font-family: "fredericka the great", "fantasy";
    font-size: 70px;
    line-height: 1.3em;
    letter-spacing: normal;
    font-weight: 400;
    padding: 8px 9px 9px 9px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .verse {
    margin-right: 10px;
  }

  .passage {
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    @include media("<=phone") {
      flex-wrap: wrap;
      gap: 10px;
    }
  }

  .reference {
    white-space: nowrap;
    align-self: center;
  }
}
</style>
